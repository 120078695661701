import {
  Button,
  Input,
  InputNumber,
  message,
  Modal,
  notification,
  Spin,
} from "antd";
import { useState } from "react";
import { Content } from "antd/lib/layout/layout";
import {
  checkOrgByPAN,
  createNewOrganisation,
  mapCAToOrg,
} from "../../../apis/ca";
import { connect } from "react-redux";
import ca from "../../../redux/modules/ca";
import { validateEmail } from "../../../utils/config";
import { checkUserExist } from "../../../apis/auth";

const orgCancel = ["Cancel", "Back"];
const orgSubmit = ["Next", "Submit"];

const AddOrganization = ({
  modalProps,
  caId,
  changeModalStatus,
  caReducer,
}) => {
  const [pageState, setPageState] = useState(0);
  const [isOrgAvailable, setIsOrgAvailable] = useState(false);
  const [orgDetails, setOrgDetails] = useState({
    name: "",
    username: "",
    PAN: "",
    email: "",
    phone: "",
    designation: "",
    pointOfContact: "",
    organisationName: "",
  });
  const [exsistingOrgDetails, setExistingOrgDetails] = useState({
    PAN: "",
    name: "",
    code: 0,
    username: ""
  });
  const submitForm = async () => {
    if (pageState === 0) {
      changeModalStatus(true, true);
      let org = await checkOrgByPAN(exsistingOrgDetails.username);

      changeModalStatus(true, false);
      if (org.data) {
        if (org.data.role !== "org") {
          notification.error({
            message: "Sorry! There is no organisation exist",
          });
          setExistingOrgDetails({ PAN: "", name: "", code: 0, username: "" });
          return;
        }
        if (org.data.currentCAID) {
          notification.error({
            message: "Sorry! the organisation is mapped to a CA currently",
          });
          setExistingOrgDetails({ PAN: "", name: "", code: 0, username: "" });
          return;
        }
        if (org.data.parentOrganisationId) {
          notification.error({
            message: "Sorry! the organisation is a child organisation",
          });
          setExistingOrgDetails({ PAN: "", name: "", code: 0, username: "" });
          return;
        }
        setPageState(1);
        setIsOrgAvailable(true);
        setExistingOrgDetails({
          ...exsistingOrgDetails,
          name: org.data.displayName,
          PAN: org.data.PAN
        });
        return;
      }
      setIsOrgAvailable(false);
      setOrgDetails({ ...orgDetails, PAN: exsistingOrgDetails.PAN });
      setExistingOrgDetails({ PAN: "", name: "", code: 0, username: "" });
      setPageState(1);
      return;
    }

    // Validate organisation and organisatin code.
    if (isOrgAvailable) {
      if (!exsistingOrgDetails.code) {
        message.warning("Please enter organisation code");
        return;
      }
      changeModalStatus(true, true);
      const response = await mapCAToOrg(exsistingOrgDetails);

      if (response.data.success) {
        notification.success({
          message: "Successfully Mapped the organisation into the system.",
        });
      } else {
        notification.error({
          message: "Verification Failed",
        });
      }

      resetForm();
      changeModalStatus(false, false);
      setExistingOrgDetails({ PAN: "", name: "", code: 0, username: "" });
      caReducer.updateOrganization();
      return;
    }

    // Create a new organisation here
    if (!/^[a-zA-Z ,.'-\d]+$/.test(String(orgDetails.name))) {
      message.error("Please Enter Organisation Name");
      return;
    }
    if (!/^[A-Z]{5}[0-9]{4}[A-Z]{1}/.test(orgDetails.PAN)) {
      message.error("Please Enter A Valid Organisation PAN");
      return;
    }
    if (!validateEmail(orgDetails.email)) {
      message.error("Please Enter A Valid Email");
      return;
    }
    if (!/^[a-zA-Z ,.'-\d]+$/.test(String(orgDetails.pointOfContact))) {
      message.error("Please Enter Point Of Contact");
      return;
    }
    if (!/^[a-zA-Z ,.'&-\d]+$/.test(String(orgDetails.designation))) {
      message.error("Please Enter Organisation Designation");
      return;
    }
    if (!/^\d{10}$/.test(orgDetails.phone)) {
      message.error("Please Enter Correct Organisation Phone Number");
      return;
    }


    changeModalStatus(true, true);
    await createNewOrganisation({ ...orgDetails, caId: caId, username: orgDetails.PAN });
    notification.success({
      message: "Successfully Mapped the organisation into the system.",
    });

    resetForm();
    changeModalStatus(false, false);
    setExistingOrgDetails({ PAN: "", name: "", code: 0, username: "" });
    caReducer.updateOrganization();
  };

  const resetForm = () => {
    if (pageState === 0) {
      setExistingOrgDetails({ PAN: "", code: 0 });
      changeModalStatus(false, false);
    } else {
      setExistingOrgDetails({ ...exsistingOrgDetails, code: 0 });
    }
    setOrgDetails({
      name: "",
      username: "",
      PAN: "",
      email: "",
      phone: "",
      designation: "",
      pointOfContact: "",
      organisationName: "",
    });
    setIsOrgAvailable(false)
    setPageState(0);
  };

  const checkUserAvailability = async (details, type) => {
    // type : 1 email
    let res = await checkUserExist({ details, type })
    if (res.data) {
      if (type === 1) {
        notification.error({ message: "Sorry user with the same email already exsist" })
        setOrgDetails({ ...orgDetails, email: "" })
      }
      if (type === 3) {
        notification.error({ message: "Sorry user with the same PAN number already exsist" })
        setOrgDetails({ ...orgDetails, PAN: "" })
      }
    }
  }

  return (
    <Modal footer={null} closable={false} centered visible={modalProps.open}>
      <Spin spinning={modalProps.loading}>
        <Content className="paper-wrapper w-full">
          <div className="modal-header-container">
            <h3 className="app-heading-3">Add Organisation</h3>
          </div>
          {isOrgAvailable && (
            <div style={{ width: "100%" }}>
              <div className="individual-structure">
                <p className="break-label">PAN</p>
                <p className="break-value">{exsistingOrgDetails.PAN}</p>
              </div>
              <div className="individual-structure">
                <p className="break-label">Organization Name</p>
                <p className="break-value">{exsistingOrgDetails.name}</p>
              </div>
              <div className="individual-structure">
                <p className="break-label">Organization Username</p>
                <p className="break-value">{exsistingOrgDetails.username}</p>
              </div>
            </div>
          )}
          <Content className="organisation-pan-fetch">
            {pageState === 0 ? (
              <div className="data-container">
                <h3 className="headings fields-heading">Organisation Username</h3>
                <Input
                  value={exsistingOrgDetails.username}
                  onChange={(e) => {
                    setExistingOrgDetails({
                      ...exsistingOrgDetails,
                      username: e.target.value,
                    });
                  }}
                  onPressEnter={submitForm}
                  placeholder="Enter Organisation Username"
                  className="input-field"
                  size={"large"}
                />
              </div>
            ) : isOrgAvailable ? (
              <div className="data-container">
                <h3 className="headings fields-heading">
                  Organisation Unique Code
                </h3>
                <InputNumber
                  value={exsistingOrgDetails.code}
                  onChange={(e) => {
                    setExistingOrgDetails({
                      ...exsistingOrgDetails,
                      code: e,
                    });
                  }}
                  placeholder="Enter Org Unique Code"
                  onPressEnter={submitForm}
                  className="input-field"
                  size={"large"}
                />
              </div>
            ) : (
              <>
                <div className="data-container">
                  <h3 className="headings fields-heading">Name</h3>
                  <Input
                    value={orgDetails.name}
                    onChange={(e) => {
                      setOrgDetails({
                        ...orgDetails,
                        name: e.target.value,
                        organisationName: e.target.value,
                      });
                    }}
                    placeholder="Enter Organizarion Name"
                    className="input-field"
                    size={"large"}
                  />
                </div>
                <div className="data-container">
                  <h3 className="headings fields-heading">PAN</h3>
                  <Input
                    value={orgDetails.PAN}
                    onChange={(e) => {
                      if (e.target.value.length < 11)
                        setOrgDetails({
                          ...orgDetails,
                          PAN: e.target.value.toUpperCase(),
                        });
                    }}
                    onBlur={() => checkUserAvailability(orgDetails.PAN, 3)}
                    placeholder="Enter Organizarion PAN"
                    className="input-field"
                    size={"large"}
                  />
                </div>
                <div className="data-container">
                  <h3 className="headings fields-heading">Email</h3>
                  <Input
                    value={orgDetails.email}
                    onChange={(e) =>
                      setOrgDetails({ ...orgDetails, email: e.target.value })
                    }
                    onBlur={() => checkUserAvailability(orgDetails.email, 1)}
                    placeholder="Enter Organizarion Email"
                    className="input-field"
                    size={"large"}
                  />
                </div>
                <div className="data-container">
                  <h3 className="headings fields-heading">
                    POC(Point of contact) name
                  </h3>
                  <Input
                    value={orgDetails.pointOfContact}
                    onChange={(e) =>
                      setOrgDetails({
                        ...orgDetails,
                        pointOfContact: e.target.value,
                      })
                    }
                    placeholder="Enter Organizarion POC"
                    className="input-field"
                    size={"large"}
                  />
                </div>
                <div className="data-container">
                  <h3 className="headings fields-heading">
                    POC(Point of contact) Designation
                  </h3>
                  <Input
                    value={orgDetails.designation}
                    onChange={(e) =>
                      setOrgDetails({
                        ...orgDetails,
                        designation: e.target.value,
                      })
                    }
                    placeholder="Enter POC's Designation"
                    className="input-field"
                    size={"large"}
                  />
                </div>
                <div className="data-container">
                  <h3 className="headings fields-heading">Phone Number</h3>
                  <Input
                    value={orgDetails.phone}
                    onChange={(e) => {
                      if (e.target.value.length < 11) {
                        setOrgDetails({ ...orgDetails, phone: e.target.value });
                      }
                    }}
                    placeholder="Enter Organizarion Phone Number"
                    className="input-field"
                    size={"large"}
                  />
                </div>
              </>
            )}
          </Content>
          <Content className="modal-submit-container">
            <Button
              onClick={() => {
                resetForm();
              }}
              className="cancel-button"
              style={{
                border: "none",
                boxShadow: "none",
                marginRight: "0px",
              }}
            >
              {orgCancel[pageState]}
            </Button>
            <Button
              onClick={submitForm}
              className="submit-button"
              style={{
                marginRight: "0px",
                padding: "0px 24px",
              }}
            >
              {orgSubmit[pageState]}
            </Button>
          </Content>
        </Content>
      </Spin>
    </Modal>
  );
};

const AddOrganizationContainer = connect(
  (state) => ({}),
  (dispatch) => ({
    caReducer: ca.getActions(dispatch),
  })
)(AddOrganization);

export default AddOrganizationContainer;
