import { useState } from 'react';
import { Layout, Button, Input, Spin, notification } from 'antd';
import { withRouter } from 'react-router-dom';
import * as auth from '../../../../apis/auth'
const { Content } = Layout;

const ChangePassword = (props) => {
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState("")
    const changePassword = async () => {
        setLoading(true);
        if (!password.match(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/)) {
            notification.error({ message: "Password must have a length of 8 with atleast one lowercase, one uppercase, one integer." })
            setLoading(false)
            return;
        }
        let data = await auth.setPassword({ username: props.user.username, password })
        if (data.data.success) {
            props.changePageState()
        }
        else {
            notification.error({ message: "Sorry unable to set the password" })
        }
        setLoading(false)
    }

    return (
        <Spin spinning={loading} delay={500}>
            <Content className="stages">
                <div className="main-container">
                    <div className="header-display">
                        <p>Set New Password</p>
                    </div>
                    <div className="password-container">
                        <label className="login-info-text">
                            Password
                        <br />
                            <Input.Password
                                style={{ "color": "#9898a5", "borderStyle": "hidden", "marginTop": "1rem", "outline": "none", "borderBottom": "0.01rem #9898a5 solid", width: '95%', height: '3rem', "boxShadow": "none" }}
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                            />
                        </label>
                    </div>
                </div>
                <div className="submit-container-password">
                    <Button onClick={changePassword} className="submit-button" type="primary" htmlType="submit">
                        Continue
                    </Button>
                </div>
            </Content>
        </Spin>
    )
}

export default withRouter(ChangePassword);