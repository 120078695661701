import { Col, notification, Row, Spin } from "antd";
import { InputField, NormalButton } from "../../ComponentLibrary";
import { useState, useEffect } from "react";
import yellowEditIcon from "../../../assets/common/yellowEditIcon.svg";
import auth from "../../../redux/modules/auth";
import { connect } from "react-redux";
import { updateCADetails } from "../../../apis/ca";

const GeneralInformation = ({user, authReducer}) => {
  const [editable, setEditable] = useState(false);
  const [loading, setLoading] = useState(false);
  const toggleEdit = () => setEditable(!editable);

  const [generalInformation, setGeneralInformation] = useState({
    firmName: user.firmName,
    displayName: user.displayName,
    email: user.primaryEmail,
  });

  useEffect(() => {
    setLoading(true);
    setGeneralInformation({
      firmName: user.firmName,
      displayName: user.displayName,
      email: user.primaryEmail,
    });
    setLoading(false);
  }, [user]);

  const updateGeneralInformationField = (e) => {
    setGeneralInformation({
      ...generalInformation,
      [e.target.name]: e.target.value,
    });
  };

  const submitForm = async () => {
    setLoading(true);
    let res = await updateCADetails({ ...generalInformation});
    if(res.data.success) {
      authReducer.updateUser(user._id);
      notification.success({message: "Successfully updated the data"})
    }
    else {
      notification.success({message: "Sorry unable to update the data"})
    }
    setEditable(false);
    setLoading(false);
  };

  return (
    <div className="app-white-card">
      <Spin spinning={loading}>
        <div className="app-flex-container-1">
          <h3 className="app-heading-3">General Information</h3>
          {editable ? (
            <div>
              <NormalButton
                type={1}
                buttonText="Save"
                margin={[0, 0]}
                onClick={submitForm}
              />
              <NormalButton
                type={5}
                buttonText="Cancel"
                onClick={toggleEdit}
                margin={[0, 0]}
              />
            </div>
          ) : (
            <NormalButton
              type={8}
              buttonText="Edit"
              block={true}
              icon={yellowEditIcon}
              onClick={toggleEdit}
            ></NormalButton>
          )}
        </div>
        <Row gutter={[50, 30]} className="company-data ">
          <Col span={12}>
            <div className="app-input-container">
              <div className="company-address-individual-data-container">
                <label className="app-label-1">Firm name</label>
                <InputField
                  disabled={!editable}
                  block={true}
                  name="firmName"
                  onChange={updateGeneralInformationField}
                  value={generalInformation.firmName}
                ></InputField>
              </div>
            </div>
            <div className="app-input-container">
              <div className="company-address-individual-data-container">
                <label className="app-label-1">Email</label>
                <InputField
                  disabled={!editable}
                  block={true}
                  name="email"
                  onChange={updateGeneralInformationField}
                  value={generalInformation.email}
                ></InputField>
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className="app-input-container">
              <div className="company-address-individual-data-container">
                <label className="app-label-1">Firm display name</label>
                <InputField
                  disabled={!editable}
                  block={true}
                  name="displayName"
                  onChange={updateGeneralInformationField}
                  value={generalInformation.displayName}
                ></InputField>
              </div>
            </div>
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

const GeneralInformationContainer = connect(
  (state) => ({
    user: state.auth.user,
  }),
  (dispatch) => ({
    authReducer: auth.getActions(dispatch),
  })
)(GeneralInformation);

export default GeneralInformationContainer;
