import moment from "moment";
import { Link } from "react-router-dom";

const BroadCast = ({ broadcastMessage }) => {

  return (
    <div className="paper-wrapper">
      <div className="header-component flex justify-between align-center m-bottom-20">
        <h3 className="app-heading-3">Broadcast Message</h3>
        <Link to="/ca/broadcast" className="header-link">
          View All
        </Link>
      </div>
      {broadcastMessage.sort((a, b) => a.updatedAt > b.updatedAt ? -1 : 1).slice(0, 4).map((val, index) => (
        <BroadCastList key={`broadcast-${index}`} {...val} />
      ))}
    </div>
  );
};

const BroadCastList = ({ updatedAt, title, message }) => (
  <div
    style={{ marginBottom: 20 }}
    className="flex justify-between align-center"
  >
    <div style={{ marginLeft: 10 }}>
      <p style={{ fontWeight: 600, fontSize: 15 }}>{title}</p>
      <p className="app-label-3">{message}</p>
    </div>
    <p className="app-label-4">{moment(updatedAt).format("ll")}</p>
  </div>
);

export default BroadCast;
