import { Link } from "react-router-dom";
import ReimUpdate from "../../../assets/update/ReimUpdate.svg";
import TaxUpdate from "../../../assets/update/TaxUpdate.svg";

const Updates = () => {
  const updateList = [
    {
      heading: "Deduction",
      image: TaxUpdate,
      subheading: "Investment (section 80c) | Life Insurance | Filename.pdf",
      time: "2 June",
    },
    {
      heading: "Reimbursement",
      image: ReimUpdate,
      subheading: "Reimbursement Type | 20th Feb, 2021",
      time: "2 June",
    }
  ];
  return (
    <div className="paper-wrapper" style={{height: 500, overflowX: "scroll"}}>
      <div className="header-component">
        <h3 className="app-heading-3">Updates</h3>
        <Link to="/" className="header-link">
          View All
        </Link>
      </div>
      {updateList.map((val, index) => (
        <UpdatesList key={`update-${index}`} {...val} />
      ))}
    </div>
  );
};

const UpdatesList = ({ heading, subheading, image, time }) => (
  <div
    style={{ marginBottom: 20 }}
    className="flex justify-between align-center"
  >
    <div className="info-section flex align-center">
      <img alt={subheading} src={image} />
      <div style={{ marginLeft: 10 }}>
        <p style={{ fontWeight: 600, fontSize: 15 }}>{heading}</p>
        <p className="app-label-3">{subheading}</p>
      </div>
    </div>
    <p className="update-time">{time}</p>
  </div>
);

export default Updates;
