import './orgstyles.less';
import { Layout } from 'antd';
import { withRouter } from 'react-router-dom';
import {
    ChangePassword,
} from './Stages'
import auth from './../../redux/modules/auth';
import { connect } from 'react-redux';
import PropTypes from 'prop-types'

const { Content } = Layout;

const OrgLoginFlow = (props) => {
    const pageHeadings = ["Getting Started", "We're in this together", "We're in this together", "One last mile to go"]

    const changePageState = async () => {
        const { authReducer, user } = props;
        authReducer.changeLoginState({ id: user._id, loginstate: (user.loginstate + 1) });
    }

    return (
        <Layout>
            <Content className="first-login-container">
                <section className="stage-container">
                    <h3 className="stage-heading">{pageHeadings[props.user.loginstate]}</h3>
                    <ChangePassword user={props.user} changePageState={changePageState} />
                </section>
            </Content>
        </Layout>
    )
}

OrgLoginFlow.propTypes = {
    user: PropTypes.object.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    loginstate: PropTypes.bool.isRequired,
}

const OrgLoginFlowContainer = connect(
    state => ({
        user: state.auth.user,
        isLoggedIn: state.auth.isLoggedIn,
        loginstate: state.auth.loginstate
    }),
    dispatch => ({
        authReducer: auth.getActions(dispatch)
    })
)(OrgLoginFlow)

export default withRouter(OrgLoginFlowContainer);
