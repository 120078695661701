import { withRouter } from "react-router-dom"
import { NormalButton } from "../../ComponentLibrary"
const CompletionMessage = (props) => {
    return (
        <div className="completion-message-container">
            <div className="completion-message-container-message-container">
                <h1 className="completion-message-heading">We’re getting things ready for you…</h1>
                <p className="completion-message-para">Thank you for signing up. We’ve received your request. Our representatives are on their way and will get back to you shortly. We appreciate your patience.</p>
                <div className="completion-message-button-container">
                    <NormalButton
                        buttonText="Log in"
                        onClick={() => props.history.push("/login")}
                        type={1}
                    />
                </div>
            </div>
        </div>
    )
}

export default withRouter(CompletionMessage);