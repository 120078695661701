import axios from "./index";

export async function checkOrgByPAN(data) {
  return axios.get(`user/check/organization/${data}`);
}

export async function getOrganizations() {
  return axios.get("ca/organizations");
}

export async function getIndividualUsers() {
  return axios.get("ca/users");
}

export async function mapCAToOrg(data) {
  return axios.post("map/ca/organization", data);
}

export async function createNewOrganisation(data) {
  return axios.post("user/create/organization", data);
}

export async function removeCAFromOrg(data) {
  return axios.post("remove/ca/organization", data);
}

export async function newSupportRequest(data) {
  return axios.post('support/new/request', data);
}

export async function getSupportRequestData(data) {
  return axios.get(`support/from/requests/${data}`);
}

export async function removeS3Object(data) {
  return axios.delete(`s3/image/${data}`)
}

export async function updateAddress(data) {
  return axios.put(`user/address`, data);
}

export async function updateCADetails(data) {
  return axios.post('ca/user/update/', data);
}

export async function getBroadcastMessages(data) {
  return axios.get(`get/broadcast/${data}/2`);
}