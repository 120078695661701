import { Swiper, SwiperSlide } from "swiper/react";

const Advertisement = () => {

    return (
        <div className="ads-container">
        <Swiper  autoplay={{ delay: 3000 }}>
          {
            // photos.map((item) =>
            //   (
            //   <SwiperSlide>
            //     <div className="photo-holder">
            //         <img src={item} alt="Ads not available" />
            //     </div>
            //   </SwiperSlide>
            //   )
            // )
            
            <>
              <SwiperSlide>
                <div className="photo-holder">
                  <img src="https://i.picsum.photos/id/222/780/120.jpg?hmac=N1xyi6pjcQOfJKdtxmaMasB-CVzXBZdtyMowhAHw-Gk" alt="Ads not available" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="photo-holder">
                  <img src="https://i.picsum.photos/id/308/780/120.jpg?hmac=k3I_1aI8PEBKWxJczRDRsdlTVAGrq4swZJndcoZIIaA" alt="Ads is not available" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="photo-holder">
                  <img src="https://i.picsum.photos/id/862/780/120.jpg?hmac=5ON8vUk7GsBaYr1pLknXG5X8_J8JBhrUiQLUAhTNwrg" alt="Ads  43 not available" />
                </div>
              </SwiperSlide>
            </>
          }
        </Swiper>
      </div>
    )
}

export default Advertisement