import { connect } from "react-redux";
import { Link } from "react-router-dom";
import client from "../../../assets/Client.svg";
import org from "../../../assets/Organisation.svg"

const NumberCards = (props) => {
  return (
    <div className="paper-wrapper flex justify-between h-full align-center">
    <div className="flex align-center">
      <img alt="img" src={org}></img>
      <div className="m-left-24">
        <h1 className="font-size-18 color-grey font-weight-900">
          Organisation
        </h1>
        <p className="font-size-30 font-weight-900">{props.organisations.length}</p>
      </div>
    </div>
    <div className="flex align-center">
      <img alt="img" src={client}></img>
      <div className="m-left-24">
        <h1 className="font-size-18 color-grey font-weight-900">
          Individual client
        </h1>
        <p className="font-size-30 font-weight-700">{props.individualClients.length}</p>
      </div>
    </div>
    <Link style={{color: "#fea101"}} to="/ca/client_manage">Add Client</Link>
  </div>
  );
};

const NumberCardsContainer = connect((state) => ({
  organisations: state.ca.organisations,
  individualClients: state.ca.individualClients,
}))(NumberCards);

export default NumberCardsContainer;
