import "./normalButton.less";

const NormalButton = ({ type, block, onClick, disabled, href, icon, loading, target, fontSize, padding, buttonText, margin, heightWidth, color }) => {

    let styles = {
        color: color,
        fontSize: fontSize,
        display: block ? "block" : "inline-block",
        padding: padding ? `${padding[0]}px ${padding[1]}px` : "",
        margin: margin ? `${margin[0]}px ${margin[1]}px` : "",
        height: heightWidth ? heightWidth[0] : "",
        width: heightWidth ? heightWidth[1] : "",
    }


    return (
        <button
            className={` button-lib button-type-${type}`}
            onClick={onClick}
            disabled={disabled}
            href={href}
            target={href ? target : ""}
            style={styles}
        >
            {
                icon
                    ? <img alt="img" src={icon}></img>
                    : ""
            }
            {
                buttonText
            }
        </button>
    )
}

export default NormalButton;