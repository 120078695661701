/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import './register.less';
import { Layout, Button, Input, Checkbox, notification } from 'antd';
import { FloatLabel } from '../Common'
import { withRouter, Link } from 'react-router-dom'
import * as auth from '../../apis/auth';
import TermsAndCondition from './TermsAndCondition';
const { Content } = Layout;

function Register(props) {
    const [caDetails, setCaDetails] = useState({
        name: "",
        PAN: "",
        email: "",
        phone: "",
        firmName: ""
    })
    const [error, setError] = useState("");
    const [iserror, setIserror] = useState(false);
    const [termsAndCondition, setTermsAndCondition] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const registerEmployee = async () => {

        if (caDetails.name === "") {
            setIserror(true)
            setError("Please Enter Firm Name")
        }
        else if (caDetails.PAN === "") {
            setIserror(true)
            setError("Please Enter PAN Number")
        }
        else if (caDetails.email === "") {
            setIserror(true)
            setError("Please Enter Your Email")
        }
        else if (caDetails.phone === "") {
            setIserror(true)
            setError("Please Enter Your Phone Number")
        }
        else if (!termsAndCondition) {
            setIserror(true)
            setError("Please verify and accept the terms and condition")
        }
        else {
            setIserror(false)
            let authResult = await auth.register({...caDetails, username: caDetails.PAN});
            setCaDetails({
                name: "",
                PAN: "",
                email: "",
                phone: "",
                firmName: ""
            })
            if (authResult.data.success) {
                notification.success({
                    message: "Organisation created",
                    description: "Successfully created  the user please check your email for the default password",
                    placement: "topRight"
                })
                window.location = "/completionMessage"
            }
            else {
                notification.error({
                    message: authResult.data.message,
                    placement: "topRight"
                })
            }
        }
        if (iserror) {
            notification.error({
                message: error,
                placement: "topRight"
            })
        }
    }

    const checkUserAvailability = async (details, type) => {
        // type : 1 email
        let res = await auth.checkUserExist({ details, type })
        if (res.data) {
          if (type === 1) {
            notification.error({ message: "Sorry user with the same email already exsist" })
            setCaDetails({ ...caDetails, email: "" })
          }
          if (type === 3) {
            notification.error({ message: "Sorry user with the same PAN number already exsist" })
            setCaDetails({ ...caDetails, PAN: "" })
          }
        }
      }
    return (
        <Layout>
            <Content className="register-container">

                <div className="register-content">
                    <h2 className="register-heading">
                        Create an account
                    </h2>
                    <FloatLabel label="Firm Name" name="firmName" value={caDetails.firmName}>
                        <Input className="register-input"
                            value={caDetails.firmName} onChange={e => {
                                setCaDetails({ ...caDetails, firmName: e.target.value })
                            }} />
                    </FloatLabel>
                    <FloatLabel label="CA Name" name="name" value={caDetails.name}>
                        <Input className="register-input"
                            value={caDetails.name} onChange={e => {
                                setCaDetails({ ...caDetails, name: e.target.value })
                            }} />
                    </FloatLabel>
                    <FloatLabel label="Pan Number" name="PAN" value={caDetails.PAN}>
                        <Input className="register-input"
                            bordered={false}
                            value={caDetails.PAN}
                            onBlur={() => checkUserAvailability(caDetails.PAN, 3)}
                            onChange={e => {
                                if (e.target.value.length < 11)
                                    setCaDetails({ ...caDetails, PAN: e.target.value.toUpperCase() })
                            }} />
                    </FloatLabel>
                    <FloatLabel label="Email Address" name="email" value={caDetails.email}>
                        <Input className="register-input"
                            bordered={false}
                            onBlur={() => checkUserAvailability(caDetails.email, 1)}
                            value={caDetails.email}
                            onChange={e => setCaDetails({ ...caDetails, email: e.target.value })} />
                    </FloatLabel>
                    <FloatLabel label="Phone" name="phone" value={caDetails.phone}>
                        <Input className="register-input"
                            bordered={false}
                            value={caDetails.phone}
                            onChange={e => {
                                if (e.target.value.length < 11) {
                                    setCaDetails({ ...caDetails, phone: e.target.value })
                                }
                            }} />
                    </FloatLabel>
                    <Checkbox checked={termsAndCondition} onClick={data => setTermsAndCondition(!termsAndCondition)} className="terms-and-condition-checkbox">
                        Accept <a onClick={() => setIsModalVisible(true)}>Terms and Conditions</a> of mool finance
                    </Checkbox>
                    <TermsAndCondition isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} />
                    <div className="submit-container">
                        <Button onClick={registerEmployee} className="submit-button" type="primary" htmlType="submit">
                            Create an Account
                        </Button>
                    </div>
                    <div className="goto-login-container">
                        <Link to="/login">
                            <p className="goto-login">
                                Already have an account? Login
                        </p>
                        </Link>
                    </div>
                </div>
                <h1 className="register-name">
                    Register Your Firm Into The System Of Easy client Management
                </h1>
            </Content>
        </Layout>
    )
}

export default withRouter(Register);
