import "./App.less";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Register, Login, CompletionMessage } from "./components";
import PrivateContainer from "./config/Private/PrivateRoutes";
import React, { useEffect, useState } from "react";
import auth from "./redux/modules/auth";

function App({ isLoggedIn, authReducer, loginstate }) {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    authReducer.check();
    setLoading(false);
  }, [loading, authReducer]);

  return (
    <Router>
      <Switch>
        <Route exact path="/" render={() => <Redirect path="/ca" to={loginstate ? `ca/login_flow` : `ca/dashboard`} />} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/completionMessage" component={CompletionMessage} />
        <SecretRoute
          path="/ca"
          component={PrivateContainer}
          isAuthenticated={isLoggedIn}
        />
        <PublicRoute
          path="/login"
          component={Login}
          isAuthenticated={isLoggedIn}
        />
      </Switch>
    </Router>
  );
}

function SecretRoute({ isAuthenticated, component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          React.createElement(component, props)
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: {
                from: props.location,
              },
            }}
          />
        )
      }
    />
  );
}

function PublicRoute({ isAuthenticated, component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Redirect
            to={{
              pathname: "/",
            }}
          />
        ) : (
          React.createElement(component, props)
        )
      }
    />
  );
}

App.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
};

const AppContainer = connect(
  (state) => ({
    isLoggedIn: state.auth.isLoggedIn,
    loginstate: state.auth.loginstate,
  }),
  (dispatch) => ({
    authReducer: auth.getActions(dispatch),
  })
)(App);

export default AppContainer;
