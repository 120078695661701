import './caProfile.less';
import CAData from "./component/CAData"
import ManageAddress from "./component/ManageAddress"
import { Row, Col } from 'antd';
import { HeaderComponent } from '../Common';
import activeSetting from "../../assets/icons/activeIcons/activeSettings.svg"
import POCInformation from './component/generalInformation';


const Profile = () => {
    return (
        <div className="site-layout">
            <HeaderComponent icon={activeSetting} name="Settings" />
            <div className="app-main-content-container">
                <Row gutter={[30, 30]} style={{ padding: "10px 0" }}>
                    <Col className="gutter-row" xs={24} sm={24} md={12} lg={12}>
                        <CAData />
                    </Col>
                    <Col className="gutter-row" xs={24} sm={24} md={12} lg={12}>
                        <POCInformation />
                    </Col>
                    <Col className="gutter-row" xs={24} sm={24} md={12} lg={12}>
                        <ManageAddress />
                    </Col>
                </Row>
            </div>
        </div>

    )
}

export default Profile;
