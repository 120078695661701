const { message } = require("antd");
const {endpoint,redirectURL } = require("./env");

module.exports = {
  monthList: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  finMonthList: [
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
    "January",
    "February",
    "March",
  ],
  rootUrl: endpoint,
  redirectionURL: redirectURL,
  numformatter: function numFormatter(num) {
    num = Math.ceil(num);
    num = num + "" || "";
    let n1 = num.split(".");
    let n2 = n1[1] || null;
    n1 = n1[0].replace(/(\d)(?=(\d\d)+\d$)/g, "$1,");
    num = n2 ? n1 + "." + n2 : n1;
    return num;
  },
  validateEmail: function(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },
  deductionData: [
    {
      section: "80C",
      declaration: "employeeContributionToPF",

      label: "Employee Contribution to Provident Fund",
      bucket: "sec_80C_OnlyPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "fiveYearFixedDepositScheduledBank",
      label: "5 Years of Fixed Deposit in Scheduled Bank",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "childrenTuitionFees",
      label: "Children Tuition Fees",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "depositInNSC",
      label: "Deposit in National Savings Certificate",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "depositInNSS",
      label: "Deposit in National Savings Schemes",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "depositInPostOfficeSavingsSchemes",
      label: "Deposit in Post Office Savings Schemes",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "equityLinkedSavingsScheme",
      label: "Equity Linked Savings Scheme ( ELSS )",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "infrastructureBonds",
      label: "Infrastructure Bonds",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "interestReinvestedNSC",
      label: "Interest on NSC Reinvested",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "kisanVikasPatra",
      label: "Kisan Vikas Patra (KVP)",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "lifeInsurancePremium",
      label: "Life Insurance Premium",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "longTermInfrastructureBonds",

      label: "Long term Infrastructure Bonds",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "mutualFunds",

      label: "Mutual Funds",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "ruralBondsNABARD",
      label: "NABARD Rural Bonds",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "nationalPensionScheme",

      label: "National Pension Scheme",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "schemeNHB",

      label: "NHB Scheme",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "postOfficeTimeDepositForFiveYears",

      label: "Post office time deposit for 5 years",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "pradhanMantriSurakshaBimaYojana",

      label: "Pradhan Mantri Suraksha Bima Yojana",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "publicProvidentFund",

      label: "Public Provident Fund",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "repaymentOfHousingLoanPrincipal",

      label: "Repayment of Housing loan(Principal amount)",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "stampDutyAndRegistrationCharges",

      label: "Stamp duty and Registration charges",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "sukanyaSamriddhiYojana",

      label: "Sukanya Samriddhi Yojana",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "unitLinkedInsurancePremium",

      label: "Unit Linked Insurance Premium (ULIP)",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80CCC",
      declaration: "contributionPensionFund",

      label: "Contribution to Pension Fund",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80CCC - Insurance Premium",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80D",
      declaration: "preventiveHealthCheckupDependantParents",

      label: "Preventive Health Checkup - Dependant Parents",
      limit: 5000,
      bucket: "sec_80D",
      bucketLabel: "Section 80D - Medical Insurance",
      deductionPresentationGroup: "Section 80D, 80DD, 80DDB, 80U",
    },
    {
      section: "80D",
      declaration: "medicalBillsVerySeniorCitizen",

      label: "Medical Bills - Very Senior Citizen",
      limit: 50000,
      bucket: "sec_80D",
      bucketLabel: "Section 80D - Medical Insurance",
      deductionPresentationGroup: "Section 80D, 80DD, 80DDB, 80U",
    },
    {
      section: "80D",
      declaration: "medicalInsurancePremiumBelow60",

      label: "Medical Insurance Premium For Person Below 60",
      limit: 25000,
      bucket: "sec_80D",
      bucketLabel: "Section 80D - Medical Insurance",
      deductionPresentationGroup: "Section 80D, 80DD, 80DDB, 80U",
    },
    {
      section: "80D",
      declaration: "medicalInsurancePremium60YearsAndAbove",

      label: "Medical Insurance Premium For Person 60 Years And Above",
      limit: 50000,
      bucket: "sec_80D",
      bucketLabel: "Section 80D - Medical Insurance",
      deductionPresentationGroup: "Section 80D, 80DD, 80DDB, 80U",
    },
    {
      section: "80D",
      declaration: "medicalInsurancePremiumDependantParentsBelow60",
      label: "Medical Insurance Premium - Dependant Parents Below 60",
      limit: 25000,
      bucket: "sec_80D",
      bucketLabel: "Section 80D - Medical Insurance",
      deductionPresentationGroup: "Section 80D, 80DD, 80DDB, 80U",
    },
    {
      section: "80D",
      declaration: "medicalInsurancePremiumDependantParents60YearsAbove",
      label: "Medical Insurance Premium - Dependant Parents 60 Years and Above",
      limit: 50000,
      bucket: "sec_80D",
      bucketLabel: "Section 80D - Medical Insurance",
      deductionPresentationGroup: "Section 80D, 80DD, 80DDB, 80U",
    },
    {
      section: "80D",
      declaration: "preventiveHealthCheckUp",
      label: "Preventive Health Check-up",
      limit: 5000,
      bucket: "sec_80D",
      bucketLabel: "Section 80D - Medical Insurance",
      deductionPresentationGroup: "Section 80D, 80DD, 80DDB, 80U",
    },
    {
      section: "80DD",
      declaration: "medicalTreatmentOrInsuranceHandicappedDependant",
      label: "Medical Treatment / Insurance of handicapped Dependant",
      limit: 75000,
      bucket: "sec_80DD_generalDisability",
      bucketLabel: "Section 80DD - Disabled Dependent",
      deductionPresentationGroup: "Section 80D, 80DD, 80DDB, 80U",
    },
    {
      section: "80DD",
      declaration: "medicalTreatmentOrInsuranceSevereHandicappedDependant",

      label: "Medical Treatment / Insurance of handicapped Dependant (Severe)",
      limit: 125000,
      bucket: "sec_80DD_severeDisability",
      bucketLabel: "Section 80DD - Disabled Dependent",
      deductionPresentationGroup: "Section 80D, 80DD, 80DDB, 80U",
    },
    {
      section: "80DDB",
      declaration: "medicalTreatmentSpecifiedDisease",

      label: "Medical Treatment ( Specified Disease only )",
      limit: 40000,
      bucket: "sec_80DDB_Others",
      bucketLabel: "Section 80DDB - Medical Expenditure",
      deductionPresentationGroup: "Section 80D, 80DD, 80DDB, 80U",
    },
    {
      section: "80DDB",
      declaration: "medicalTreatmentSpecifiedDiseaseSeniorCitizen",

      label: "Medical Treatment (Specified Disease only)- Senior Citizen",
      limit: 100000,
      bucket: "sec_80DDB_Senior",
      bucketLabel: "Section 80DDB - Medical Expenditure",
      deductionPresentationGroup: "Section 80D, 80DD, 80DDB, 80U",
    },
    {
      section: "80DDB",
      declaration: "medicalTreatmentSpecifiedDiseaseVerySeniorCitizen",

      label: "Medical Treatment (Specified Disease only)- Very Senior Citizen",
      limit: 100000,
      bucket: "sec_80DDB_Senior",
      bucketLabel: "Section 80DDB - Medical Expenditure",
      deductionPresentationGroup: "Section 80D, 80DD, 80DDB, 80U",
    },
    {
      section: "80U",
      declaration: "permanentPhysicalDisabilityBelow40Prcnt",

      label: "Permanent Physical disability (Below 40%)",
      limit: 75000,
      bucket: "sec_80U_generalDisability",
      bucketLabel: "Section 80U - Physical Disability",
      deductionPresentationGroup: "Section 80D, 80DD, 80DDB, 80U",
    },
    {
      section: "80U",
      declaration: "permanentPhysicalDisabilityAbove40Prcnt",

      label: "Permanent Physical disability (Above 40%)",
      limit: 125000,
      bucket: "sec_80U_criticalDisability",
      bucketLabel: "Section 80U - Physical Disability",
      deductionPresentationGroup: "Section 80D, 80DD, 80DDB, 80U",
    },
    {
      section: "80CCD1(B)",
      declaration: "contributionNPS2015_CCD1B",

      label: "80CCD1(B) - Contribution to NPS 2015",
      limit: 50000,
      bucket: "sec_80CCD_1B",
      deductionPresentationGroup: "Other Sections",
    },
    {
      section: "80CCG",
      declaration: "rajivGandhiEquityScheme",

      label: "80CCG - Rajiv Gandhi Equity Scheme",
      limit: 25000,
      bucket: "sec_80CCG",
      deductionPresentationGroup: "Other Sections",
    },
    {
      section: "80E",
      declaration: "interestLoanHigherSelfEducation",

      label: "80E - Interest on Loan of higher Self education",
      bucket: "sec_80E",
      deductionPresentationGroup: "Other Sections",
    },

    {
      section: "80EE",
      declaration: "additionalInterestOnHousingLoanBorrowed1stApr2016",

      label:
        "80EE - Additional Interest on housing loan borrowed as on 1st Apr 2016",
      limit: 50000,
      bucket: "sec_80EE",
      deductionPresentationGroup: "Other Sections",
    },
    {
      section: "80EEA",
      declaration: "additionalInterestOnHousingLoanBorrowed1stApr2019",

      label:
        "80EEA - Additional Interest on Housing loan borrowed as on 1st Apr 2019",
      limit: 150000,
      bucket: "sec_80EE",
      deductionPresentationGroup: "Other Sections",
    },
    {
      section: "80EEB",
      declaration: "interestOnElectricVehicleBorrowed1stApr2019",

      label: "80EEB - Interest on Electric Vehicle borrowed as on 1st Apr 2019",
      limit: 150000,
      bucket: "sec_80EE",
      deductionPresentationGroup: "Other Sections",
    },
    {
      section: "80TTA",
      declaration:
        "interestSavingsAccountDepositFDPostOfficeCooperativeSociety",

      label:
        "80TTA - Interest on Deposits in Savings Account, FDs, Post Office And Cooperative Society",
      limit: 10000,
      bucket: "sec_80TTA",
      deductionPresentationGroup: "Other Sections",
    },
    {
      section: "80TTB",
      declaration:
        "interestSavingsAccountDepositFDPostOfficeCooperativeSocietySeniorCitizen",

      label:
        "80TTB - Interest on Deposits in Savings Account, FDs, Post Office And Cooperative Society for Senior Citizen",
      limit: 50000,
      bucket: "sec_80TTB",
      deductionPresentationGroup: "Other Sections",
    },
    {
      section: "80G",
      declaration: "donation100PrcntExemption",

      label: "80G - Donation - 100% Exemption",
      bucket: "sec_80G",
      deductionPresentationGroup: "Other Sections",
    },
    {
      section: "80G",
      declaration: "donation50PrcntExemption",

      label: "80G - Donation - 50% Exemption",
      bucket: "sec_80G",
      deductionPresentationGroup: "Other Sections",
    },
    {
      section: "80G",
      declaration: "donationChildrenEducation",

      label: "80G - Donation - Children Education",
      bucket: "sec_80G",
      deductionPresentationGroup: "Other Sections",
    },
    {
      section: "80G",
      declaration: "donationPoliticalParties",

      label: "80G - Donation - Political Parties",
      bucket: "sec_80G",
      deductionPresentationGroup: "Other Sections",
    },
  ],
  deducationList: {
    "80C": {
      sectionName: "Section 80C ",
      sectionType: "Investment",
    },
    "80D": {
      sectionName: "Section 80D",
      sectionType: "Medical Insurance",
    },
    "80E": {
      sectionName: "Section 80E",
      sectionType: "Education Loan",
    },
    "80EE": {
      sectionName: "Section 80EE",
      sectionType: "Home Loan Interest",
    },
    "80TTA": {
      sectionName: "Section 80TTA",
      sectionType: "Interest on savings account",
    },
    "80G": {
      sectionName: "Section 80G ",
      sectionType: "Donation to charitable Organisation",
    },
    "80CCC": {
      sectionName: "Section 80CCC",
      sectionType: "Contribution to pension plan/ Annuity fund",
    },
    "80CCD(2)": {
      sectionName: "Section 80CCD(2)",
      sectionType: "Employer contribution in NPS",
    },
    "80DD": {
      sectionName: "Section 80DD",
      sectionType: "Disabled Dependent Deduction",
    },
    "80U": {
      sectionName: "Section 80U",
      sectionType: "Disability",
    },
    "80DDB": {
      sectionName: "Section 80DDB",
      sectionType: "Disability",
    },
  },
  beforeFileUpload: function(file) {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "application/pdf";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error("Image must smaller than 5MB!");
    }
    return isJpgOrPng && isLt2M;
  },
};
