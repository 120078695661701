import { HeaderComponent } from "../Common";
import "./Dashboard.less";
import activeDashboard from "../../assets/icons/activeIcons/activeDashboard.svg";
import { Content } from "antd/lib/layout/layout";
import { Col, Row } from "antd";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import NumberCardsContainer from "./components/numberCards";
import BroadCast from "./components/Broadcast";
import Updates from "./components/Updates";
import Advertisement from "./components/Advertisement";

const Dashboard = (props) => {
  return (
    <section className="site-layout">
      <HeaderComponent icon={activeDashboard} name="Dashboard" />
      <Content className="app-main-content-container">
        <Row className="top-row" gutter={[20, 20]}>
          <Col xs={24} sm={24} md={16} lg={16}>
            <Row gutter={[20, 20]}>
            <Col xs={24} sm={24} md={24} lg={24}>
                <h1 className="app-heading-1">Hello, {props.user.displayName}</h1>
                <p className="app-label-3">This is what we have got for you today</p>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24}>
                <NumberCardsContainer />
              </Col>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Updates />
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8}>
            <Row gutter={[20, 20]}>
              <Col xs={24} sm={24} md={24} lg={24}>
                <BroadCast broadcastMessage={props.broadcastMessage} />
              </Col>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Advertisement />
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
    </section>
  );
};

Dashboard.propTypes = {
  user: PropTypes.object.isRequired,
  organisations: PropTypes.array.isRequired,
};

const DashboardContainer = connect((state) => ({
  user: state.auth.user,
  organisations: state.ca.organisations,
  broadcastMessage: state.ca.broadcastMessage,
}))(Dashboard);

export default DashboardContainer;

/*

*/
