import { getIndividualUsers, getOrganizations, getBroadcastMessages } from "../../apis/ca";
import ReducerFactory from "../utils/reducerFactory";

const initialState = {
    loading: false,
    organisations: [],
    individualClients: [],
    broadcastMessage: [],
};
const reducerFactory = new ReducerFactory("ca", initialState);

reducerFactory.addAction(
    "LOGIN_LOADING",
    "loading",
    (status) => status,
    (state, action) => {
        state.loading = action.data;
        const newState = Object.assign({}, state);
        return newState;
    }
);

reducerFactory.addAction(
    "CA",
    "initiateCA",
    async (data) => {
        let organisations = await getOrganizations();
        let individualClients = await getIndividualUsers();
        let broadCastData = await getBroadcastMessages(2);
        return {
            organisations: organisations.data,
            individualClients: individualClients.data,
            broadCastData: broadCastData.data
        }
    },
    (state, action) => {
        state.organisations = action.data.organisations;
        state.individualClients = action.data.individualClients;
        state.loading = false;
        state.broadcastMessage = action.data.broadCastData;
        const newState = Object.assign({}, state);
        return newState;
    }
);

reducerFactory.addAction(
    "CA_UPDATE_ORGANIZATION",
    "updateOrganization",
    async (data) => {
        let organisations = await getOrganizations();
        return { organisations: organisations.data }
    },
    (state, action) => {
        state.organisations = action.data.organisations;
        state.loading = false;
        const newState = Object.assign({}, state);
        return newState;
    }
);

reducerFactory.addAction(
    "CA_BROADCAST_MESSAGE",
    "caBroadcastMessage",
    async (data) => {
        reducerFactory.action("loading", true);
        let res = await getBroadcastMessages(data);
        return res.data;
    },
    (state, action) => {
        if (action.data) {
            state.broadcastMessage = action.data;
        }
        state.loading = false;
        const newState = Object.assign({}, state);
        return newState;
    }
);


export default reducerFactory;
