import { useState } from "react";
import {
  Button,
  Row,
  Col,
  Spin,
  Input,
  message,
  Upload,
  Modal,
  notification,
} from "antd";
import auth from "../../../redux/modules/auth";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { LoadingOutlined, UserOutlined } from "@ant-design/icons";
import config from "../../../utils/config";
import organisationLogo from "../../../assets/common/organisation_temporary_logo.svg";
import { InputField, NormalButton } from "../../ComponentLibrary";
import { changePassword } from "../../../apis/auth";
const { Dragger } = Upload;

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 5;
  if (!isLt2M) {
    message.error("Image must smaller than 5MB!");
  }
  return isJpgOrPng && isLt2M;
}

const CAData = (props) => {
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isResetModalVisible, setIsResetModalVisible] = useState(false);
  const [resetPassword, setResetPassword] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showRestModal = () => {
    setIsResetModalVisible(true);
  };

  const handleResetCancel = () => {
    setResetPassword({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
    setIsResetModalVisible(false);
  };

  const handleResetPassword = (e) => {
    setResetPassword({
      ...resetPassword,
      [e.target.name]: e.target.value,
    });
  };
  const onResetPassSubmit = async () => {
    if (
      !resetPassword.oldPassword ||
      !resetPassword.newPassword ||
      !resetPassword.confirmPassword
    ) {
      notification.error({ message: "Field is required" });
      return;
    }
    if (resetPassword.oldPassword === resetPassword.newPassword) {
      notification.error({
        message: "Your new Password cannot be the same as your Old Password",
      });
      return;
    }
    if (resetPassword.newPassword !== resetPassword.confirmPassword) {
      notification.error({
        message: "New Password and Confirm Password are not same",
      });
      return;
    }
    if (
      resetPassword.oldPassword !== resetPassword.newPassword &&
      resetPassword.newPassword === resetPassword.confirmPassword
    ) {
      setLoading(true);
      const response = await changePassword({
        username: props.user.username,
        oldPassword: resetPassword.oldPassword,
        newPassword: resetPassword.newPassword,
      });
      setLoading(false);
      if (response.data.success) {
        notification.success({ message: "Password Changed Successfully!" });
        handleResetCancel();
      } else {
        if (response.data.message === "WRONG_CREDENTIALS") {
          notification.error({
            message: "Incorrect Old password!, Please try again!",
          });
        } else {
          notification.error({
            message: "Can not change your password, Please try again!",
          });
        }
      }
    }
  };

  const handleChange = async (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      const { user, authReducer } = props;
      authReducer.updateUser(user._id);
      setLoading(false);
      handleCancel();
    }
  };

  const uploadProps = {
    name: "files",
    action: `${config.rootUrl}/api/auth/user/picture`,
    headers: {
      authorization: localStorage.getItem("mool_ca"),
    },
    onChange(info) {
      if (info.file.status === "done") {
        const { response } = info.file;
        console.log(response);
        message.success(`${info.file.name} file uploaded successfully`);
        props.authReducer.updateImage(response.data.image);
        handleCancel();
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const UploadButton = () => (
    <div className="upload-container" style={{ margin: "12px 0px" }}>
      {loading ? <LoadingOutlined /> : <UserOutlined />}
      <div>Upload</div>
    </div>
  );

  return (
    <div className="app-white-card organisation-data-container">
      <div className="app-flex-container-1 organisation-data-heading-container">
        <div className="organisation-name-logo-container">
          <div className="current-logo-holder-has-logo">
            <img alt="img" className="current-logoo" src={props.image}></img>
          </div>
          <h2 className="app-heading-2">{props.user.displayName}</h2>
        </div>
        <div>
          {props.image ? (
            <div className="current-logo-holder-has-logo">
              <NormalButton
                type={8}
                buttonText="Update image"
                onClick={showModal}
              ></NormalButton>
              <Modal
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                centered
                width={400}
                closable={false}
              >
                <div className="update-img-modal-header">
                  <h3 className="modal-heading">Update Image</h3>
                  <Button
                    className="modal-cancel-btn"
                    onClick={() => handleCancel()}
                  >
                    Cancel
                  </Button>
                </div>
                <div className="update-img-modal-body">
                  <Dragger
                    name="avatar"
                    listType="picture-card"
                    className="avatar-update"
                    maxCount={1}
                    beforeUpload={beforeUpload}
                    onChange={handleChange}
                    showUploadList={false}
                    {...uploadProps}
                  >
                    <div className="update-img-modal-text">
                      <UploadButton />
                      <p>Click here or drop the image here</p>
                    </div>
                  </Dragger>
                </div>
              </Modal>
            </div>
          ) : (
            <div className="current-logo-holder-has-logo">
              <img alt="img" className="default-logo" src={organisationLogo}></img>
              <NormalButton
                type={8}
                buttonText="Add image"
                onClick={showModal}
              ></NormalButton>
              <Modal
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                centered
                width={400}
                closable={false}
              >
                <div className="update-img-modal-header">
                  <h3 className="modal-heading">Add Image</h3>
                  <Button
                    className="modal-cancel-btn"
                    onClick={() => handleCancel()}
                  >
                    Cancel
                  </Button>
                </div>
                <div className="update-img-modal-body">
                  <Dragger
                    name="avatar"
                    listType="picture-card"
                    className="avatar-update"
                    maxCount={1}
                    beforeUpload={beforeUpload}
                    onChange={handleChange}
                    {...uploadProps}
                    showUploadList={false}
                  >
                    <UploadButton />
                    <p>Click here or drop the image here</p>
                  </Dragger>
                </div>
              </Modal>
            </div>
          )}
        </div>
        <div>
          <Button
            className="submit-button submit-button"
            onClick={showRestModal}
          >
            Reset Password
          </Button>
          <Modal
            visible={isResetModalVisible}
            onOk={handleOk}
            onCancel={handleResetCancel}
            footer={null}
            centered
            width={400}
            closable={false}
          >
            <Spin spinning={loading} size="large">
              <div className="update-img-modal-header">
                <h3 className="modal-heading">Reset Password</h3>
                <Button
                  className="modal-cancel-btn"
                  onClick={() => handleResetCancel()}
                >
                  Cancel
                </Button>
              </div>
              <div>
                <div className="claim-reimbursement-data-container">
                  <label className="general-ant-field-label">
                    Old Password
                  </label>
                  <Input.Password
                    background="#f7f7fa"
                    name="oldPassword"
                    onChange={handleResetPassword}
                    value={resetPassword.oldPassword}
                  />
                </div>
                <div className="claim-reimbursement-data-container">
                  <label className="general-ant-field-label">
                    New Password
                  </label>
                  <Input.Password
                    name="newPassword"
                    background="#f7f7fa"
                    onChange={handleResetPassword}
                    value={resetPassword.newPassword}
                  />
                </div>
                <div className="claim-reimbursement-data-container">
                  <label className="general-ant-field-label">
                    Confirm Password
                  </label>
                  <Input.Password
                    name="confirmPassword"
                    background="#f7f7fa"
                    onChange={handleResetPassword}
                    value={resetPassword.confirmPassword}
                  />
                </div>
                <div>
                  <NormalButton
                    buttonText="Change Password"
                    onClick={onResetPassSubmit}
                    type={1}
                    margin={[8, 0]}
                    block={true}
                  />
                </div>
              </div>
            </Spin>
          </Modal>
        </div>
      </div>
      <Row gutter={[50, 30]} className="company-data ">
        <Col span={12}>
          <div className="app-input-container">
            <label className="app-label-1">PAN Number</label>
            <InputField
              block={true}
              value={props.user.PAN}
              disabled="disabled"
            ></InputField>
          </div>
        </Col>
        <Col span={12}>
          <div className="app-input-container">
            <label className="app-label-1">Phone Number</label>
            <InputField
              block={true}
              value={props.user.phone}
              disabled="disabled"
            ></InputField>
          </div>
        </Col>
      </Row>
    </div>
  );
};

CAData.propTypes = {
  user: PropTypes.object.isRequired,
};

const CADataContainer = connect(
  (state) => ({
    user: state.auth.user,
    image: state.auth.image,
  }),
  (dispatch) => ({
    authReducer: auth.getActions(dispatch),
  })
)(CAData);

export default CADataContainer;
