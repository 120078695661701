import { Col, Row } from "antd";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import "./ClientManage.less";
import { HeaderComponent } from "../Common";
import OrganizationList from "./components/OrganizationList";
// import IndividualClientList from "./components/IndividualClientList";
import ClientManagementHeaderIcon from "../../assets/icons/headerIcons/clientManagementHeaderLogo.svg"

const ClientManage = ({ user, organisations, individualClients }) => {
    return (
        <div className="site-layoyt">
            <HeaderComponent name="Client Management" icon={ClientManagementHeaderIcon} />
            <div className="app-main-content-container">
                <Row gutter={24}>
                    <Col sm={24} md={24}>
                        <OrganizationList caId={user.caId} organizations={organisations} />
                    </Col>
                    {/* <Col sm={24} md={12}>
                        <IndividualClientList caId={user.caId} individualClients={individualClients} />
                    </Col> */}
                </Row>
            </div>
        </div>
    );
};

ClientManage.propTypes = {
    user: PropTypes.object.isRequired,
    organisations: PropTypes.array.isRequired,
    individualClients: PropTypes.array.isRequired,
};

const ClientManageContainer = connect(
    (state) => ({
        user: state.auth.user,
        organisations: state.ca.organisations,
        individualClients: state.ca.individualClients,
    }),
)(ClientManage);

export default ClientManageContainer;
