/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import "./Login.less";
import { Input, Button, Spin, notification } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import skeleton1 from "../../assets/Login/Group225.png";
import skeleton2 from "../../assets/Login/Group 128.svg";
import heroImage from "../../assets/Login/Group 233.png";
import profilePhotoEmployee from "../../assets/Login/emojipng.com-35999@2x.png";
import profilePhotoOrganisation from "../../assets/Login/Group 190.svg";
import { withRouter } from "react-router";
import auth from "../../redux/modules/auth";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { checkUser } from "../../apis/auth";

const Login = ({ authReducer }) => {
  const [loginDetais, setDetails] = useState({
    username: "",
    password: ""
  })
  const [userType, setUserType] = useState("");
  const [username, setusername] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const setLoginDetails = async (loginData) => {
    setIsLoading(true)

    let userDetails = await checkUser({ loginDetails: loginData })
    if (userDetails.data.success) {
      let { role, name, username: usr } = userDetails.data.data;
      setUserType(role)
      setusername(name)
      setDetails({ ...loginDetais, username: usr })
      setIsLoading(false)
    }
    else {
      notification.error({ message: "Sorry user not found" })
    }
    setIsLoading(false)
  }

  const signInUser = async (password) => {
    setIsLoading(true)
    setDetails({ ...loginDetais, password: password })
    authReducer.login({ username: loginDetais.username, password: password })
    setIsLoading(false)
  }
  const clearUser = () => {
    setusername("")
    setUserType("")
    setDetails({
      username: "",
      password: ""
    })
  }

  return (
    <div className="login-container">
      <div className="login-contianer">
        <div className="login-form-card">
          <div className="card-title-container">
            <div className="card-title-text">
              <div className="card-title-icon">
                <ExclamationCircleOutlined />
              </div>
              CA Login
            </div>
          </div>
          {userType !== "" ? (
            <LoginFormPasswordDetails
              userName={username}
              userType={userType}
              signInUser={signInUser}
              isLoading={isLoading}
              clearUser={clearUser}
            />
          ) : (
            <LoginFormPanDetails
              isLoading={isLoading}
              setLoginDetails={setLoginDetails}
            />
          )}
        </div>
      </div>
      <HeroSection />
    </div>
  );
};

const LoginFormPanDetails = ({ setLoginDetails, isLoading }) => {
  const [loginData, setLoginData] = useState("");
  const submitLoginDetails = () => {
    if (!loginData) {
      notification.error({
        message: "Please enter the username or email address",
      });
      return;
    }
    setLoginDetails(loginData);
  };
  return (
    <>
      <div className='card-text-holder'>
        <h3 className='card-text'>
          Welcome to
          <br /> Mool{" "}
          <span style={{ color: "#405cd2" }}>
            <i>Lekhakar</i>
          </span>
        </h3>
      </div>
      <Spin size="large" spinning={isLoading} delay={500}>
        <div className="login-info-holder " style={{ paddingTop: "3rem" }}>
          <label className="login-info-text">
            Username / Email Address
            <br />
            <Input
              className="input-login"
              onPressEnter={submitLoginDetails}
              value={loginData}
              onChange={(e) => {
                if (e.target.value.length < 11) setLoginData(e.target.value);
              }}
            />
          </label>
          <Button
            onClick={submitLoginDetails}
            className="button"
            type="primary"
            size={"large"}
          >
            Continue
          </Button>
          <p style={{ marginTop: "1.5rem" }}>
            <a
              style={{ paddingTop: "0.5rem", color: "black" }}
              href='/register'
            >
              New here? <span style={{ color: "#405cd2" }}>Create account</span>
            </a>
          </p>
        </div>
      </Spin>
      {/* <div className='additional-links' style={{ paddingBottom: "2rem" }}>
        <hr style={{ color: "#EAEAEE" }}></hr>
        <a style={{ paddingTop: "0.5rem" }} href="/register">
          New CA? create account
        </a>
      </div> */}
    </>
  );
};

const LoginFormPasswordDetails = ({
  userName,
  userType,
  signInUser,
  isLoading,
  clearUser,
}) => {
  const [password, setPassword] = useState("");
  const checkSignInUser = () => {
    if (!password) {
      notification.error({ message: "Please enter password" });
      return;
    }
    signInUser(password.trim());
  };

  return (
    <>
      <div className="card-text-holder">
        <h3 className="card-text">Enter your password</h3>
      </div>
      <div className="user-info-holder">
        <div style={{ marginRight: 15 }}>
          {userType === "employee" ? (
            <img
              src={profilePhotoEmployee}
              className="employee-avatar"
              alt="employee avatar"
            ></img>
          ) : userType === "org" ? (
            <img
              src={profilePhotoOrganisation}
              className="organisation-avatar"
              alt="organisation avatar"
            ></img>
          ) : userType === "ca" ? (
            <img
              src={profilePhotoEmployee}
              className="employee-avatar"
              alt="chartered accountant avatar"
            ></img>
          ) : (
            <></>
          )}
        </div>
        <div className="user-info">
          <p className="userType">{userType}</p>
          <p className="userName">{userName}</p>
        </div>
      </div>
      <Spin size="large" spinning={isLoading} delay={500}>
        <div className="login-info-holder">
          <label className="login-info-text">
            Password
            <br />
            <Input.Password
              onPressEnter={checkSignInUser}
              className="input-login"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </label>

          <Button
            onClick={checkSignInUser}
            className="button"
            type="primary"
            size={"large"}
          >
            Sign In
          </Button>
        </div>
      </Spin>
      <div className="additional-links-for-password">
        <div className="additional-links-for-password-hr"></div>
        <a onClick={clearUser}>Switch to a new account</a>
      </div>
    </>
  );
};

const HeroSection = () => {
  return (
    <div className="hero-container">
      <img src={skeleton1} alt="section 1" className="skeleton1"></img>
      <img src={heroImage} alt="heroImage" className="heroImage"></img>
      <img src={skeleton2} alt="Skeleton 2" className="skeleton2"></img>
    </div>
  );
};

Login.propTypes = {
  user: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
};

const LoginContainer = connect(
  (state) => ({
    user: state.auth.user,
    isLoggedIn: state.auth.isLoggedIn,
  }),
  (dispatch) => ({
    authReducer: auth.getActions(dispatch),
  })
)(Login);

export default withRouter(LoginContainer);
