import "./inputField.less";

const InputField = ({ block, background, value, type, name, onChange, border, placeholder, disabled, maxLength }) => {


    let styles = {
        cursor: disabled ? "default" : "",
        display: block ? "block" : "inline-block",
        backgroundColor: background ? background : "",
        borderBottom: border ? `${border} 2px solid` : "",
    }

    return (
        <input
            className="input-field-container"
            style={styles}
            value={value}
            type={type}
            name={name}
            onChange={onChange}
            placeholder={placeholder}
            disabled={disabled}
            maxLength={maxLength}
            autoComplete={"off"}
        >

        </input>
    )
}

export default InputField;