import auth from '../../../redux/modules/auth';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './Header.less'
import { Layout, Menu, Dropdown, Button } from 'antd';
import { LogoutOutlined, ChromeOutlined, UserOutlined } from '@ant-design/icons';
import organisationLogo from "../../../assets/header/organisation_temporary_logo.svg";
import EmployeeLogo from "../../../assets/header/EmployeeLogo.svg";
import { Link } from "react-router-dom";

const { Header } = Layout;

const HeaderComponent = (props) => {

    function handleMenuClick(e) {
        const { authReducer } = props
        authReducer.logout()
        window.location = "/"
    }

    const menu = (
        <Menu className="header-menu-slab flex justify-center">
            <div className="header-menu-container flex align-center justify-center">
                <div className="image-container flex align-center justify-center">
                    <img
                        alt="logo"
                        className="header-menu-logo"
                        src={
                            props.image
                                ? props.image
                                : props.user.role === "org"
                                    ? organisationLogo
                                    : EmployeeLogo
                        }
                        style={
                            props.image
                                ? {}
                                : props.user.role === "org"
                                    ? { backgroundColor: "#fff1de" }
                                    : { backgroundColor: "#fea101" }
                        }
                    />
                </div>
                <p className="header-menu-displayName">{props.user.displayName}</p>
                <p className="header-menu-email">{props.user.primaryEmail}</p>
                <p className="header-menu-PAN">{`PAN: ${props.user.PAN}`}</p>
                <div className="header-menu-line"></div>
            </div>
            <div className="menu-actions">
                <Link
                    className="flex align-center justify-start action-item"
                    to={
                        `/ca/profile`
                    }
                >
                    <UserOutlined style={{ color: "#000" }} />
                    <p className="menu-text" style={{ marginLeft: "0.5rem" }}>
                        Account
                    </p>
                </Link>
                <Link
                    className="flex align-center justify-start action-item"
                    to={`/${props.user.role}/support`}
                >
                    <ChromeOutlined style={{ color: "#000" }} />
                    <p className="menu-text" style={{ marginLeft: "0.5rem" }}>
                        Support
                    </p>
                </Link>
                <span
                    onClick={handleMenuClick}
                    className="flex align-center justify-start action-item"
                >
                    <LogoutOutlined />
                    <span className="menu-text" style={{ marginLeft: "0.5rem" }}>
                        Logout
                    </span>
                </span>
            </div>
        </Menu>
    );
    return (
        <Header className="site-layout-header" style={{ padding: 0 }}>
            <div className="header-title">
                {props.icon ? <img alt="Icon" src={props.icon} /> : <></>}
                {props.name ? <h2>{props.name}</h2> : <></>}
            </div>
            <div className="logout-formm">
                <p className="name-container">{props.user.displayName}</p>
                <Dropdown overlay={menu}>
                    <Button className="dropdown-logo-container">
                        <img
                            alt="logo"
                            className="user-logo"
                            src={
                                props.image
                                    ? props.image
                                    : props.user.role === "ca"
                                        ? organisationLogo
                                        : EmployeeLogo
                            }
                            style={
                                props.image
                                    ? {}
                                    : props.user.role === "org"
                                        ? { backgroundColor: "#fff1de" }
                                        : { backgroundColor: "#fea101" }
                            }
                        >

                        </img>
                    </Button>
                </Dropdown>

            </div>
        </Header>
    )
}

HeaderComponent.propTypes = {
    user: PropTypes.object.isRequired
}

const HeaderComponentContainer = connect(
    state => ({
        user: state.auth.user,
        image: state.auth.image
    }),
    dispatch => ({
        authReducer: auth.getActions(dispatch)
    })
)(HeaderComponent)

export default HeaderComponentContainer;