import axios from './index';

export async function check() {
  return axios.get('auth/check');
}

export async function register(data) {
  return axios.post('auth_check/register/ca', data);
}

export async function login(data) {
  return axios.post('auth_check/login', data);
}

export async function changeLoginState(data) {
  return axios.post('change/loginstate', data);
}

export async function setPassword(data) {
  return axios.post('user/set/password', data);
}

export async function checkUser(data) {
  return axios.post('user/verify', data);
}

export async function getUserFromPAN(data) {
  return axios.get(`user/form/pan/${data}`);
}

export async function changePassword(data) {
  return axios.post('auth/change/password', data);
}

export async function checkUserExist(data) {
  return axios.post('auth_check/validate/info', data);
}

export async function getUserFromId(data) {
  return axios.get(`user/form/id/${data}`);
}