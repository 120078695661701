import { Button, Input, Table } from "antd";
import { useEffect, useState } from "react";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import AddOrganization from "./AddOrganization";
import organisationLogo from "../../../assets/header/organisation_temporary_logo.svg";
import "../ClientManage.less";
import { removeCAFromOrg } from "../../../apis/ca";
import { connect } from "react-redux";
import ca from "../../../redux/modules/ca";
import config from "../../../utils/config";

const OrganizationList = ({ caId, organizations, caReducer }) => {
  const [modalProps, setModalProps] = useState({
    open: false,
    loading: false,
  });
  const [displayOrganization, setDisplayOrganization] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setDisplayOrganization([...organizations]);
  }, [organizations]);

  const changeModalStatus = (open, loading) => {
    setModalProps({ ...modalProps, open, loading });
  };

  const removeOrganisation = async (id) => {
    setLoading(true);
    await removeCAFromOrg({ id });
    caReducer.updateOrganization();
    setLoading(false);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "image",
      defaultSortOrder: "descend",
      width: "12%",
      render: (text) => (
        <img
          height={30}
          alt="org"
          width={30}
          className="organisation-list"
          src={text ? text : organisationLogo}
        />
      ),
    },
    {
      title: "",
      dataIndex: "displayName",
      defaultSortOrder: "descend",
      render: (text, row) => (
        <div className="basic-details">
          <a
            href={`${config.redirectionURL}/authenticate/${row.auth}`}
            target="_blank"
            style={{ textDecoration: 'underline' }}
            className="title" rel="noreferrer"
          >
            {text}
          </a>
          <p className="subtitle">PAN: {row.PAN}</p>
        </div>
      ),
    },
    {
      title: "Phone",
      dataIndex: "phone",
      defaultSortOrder: "descend",
      render: (text, row) => <p className="title">{text}</p>,
    },
    {
      title: "Date Added",
      dataIndex: "addedOn",
      defaultSortOrder: "descend",
      render: (text, row) => (
        <p className="title">{new Date(text).toDateString()}</p>
      ),
    },
    {
      title: "Action",
      dataIndex: "addedOn",
      defaultSortOrder: "descend",
      render: (text, row) => (
        <div className="table-action-container">
          <Button
            className="table-action-button"
            shape="circle"
            danger
            onClick={() => removeOrganisation(row._id)}
            style={{
              marginLeft: 41,
              fontSize: 20,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <DeleteOutlined style={{ fontSize: 18 }} color={"#73737b"} />
          </Button>
        </div>
      ),
    },
  ];

  const searchOrganization = (e) => {
    let newOrganization = [];
    let SearchParams = e.target.value.toLowerCase();
    newOrganization = organizations.filter((val) => {
      return Object.keys(val).some((keys) =>
        val[keys]
          .toString()
          .toLowerCase()
          .includes(SearchParams)
      );
    });
    setDisplayOrganization([...newOrganization]);
  };
  return (
    <section className="paper-wrapper">
      <AddOrganization
        modalProps={modalProps}
        caId={caId}
        changeModalStatus={changeModalStatus}
      />
      <div className="paper-header-container">
        <h3 className="paper-heading">Organisation</h3>
        <div className="paper-heading-action">
          <Button
            onClick={() => changeModalStatus(true, false)}
            size={"large"}
            className="submit-button"
          >
            <PlusOutlined /> Add Organisation
          </Button>
          <Input
            onChange={searchOrganization}
            className="search-field"
            size={"large"}
            placeholder="search"
          />
        </div>
      </div>
      <div className="body-container">
        <Table
          loading={loading}
          pagination={{ position: ["bottomCenter"] }}
          columns={columns}
          dataSource={displayOrganization}
        />
      </div>
    </section>
  );
};

const OrganizationListContainer = connect(
  (state) => ({}),
  (dispatch) => ({
    caReducer: ca.getActions(dispatch),
  })
)(OrganizationList);

export default OrganizationListContainer;
