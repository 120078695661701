import { useState, useEffect } from "react";
import { Spin, Row, Col, notification } from "antd";
import auth from "../../../redux/modules/auth";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { updateAddress } from "../../../apis/ca";
import { InputField, NormalButton } from "../../ComponentLibrary";
import yellowEditIcon from "../../../assets/common/yellowEditIcon.svg";

const ManageAddress = ({ user, addresses, authReducer }) => {
  const [editable, setEditable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState({
    addressLine1: "",
    addressLine2: "",
    landmark: "",
    city: "",
    state: "",
    pincode: "",
    country: "India",
  });

  useEffect(() => {
    setLoading(true);
    if (addresses) {
      setAddress({
        addressLine1: addresses.addressLine1 ? addresses.addressLine1 : "",
        addressLine2: addresses.addressLine2 ? addresses.addressLine2 : "",
        landmark: addresses.landmark ? addresses.landmark : "",
        city: addresses.city ? addresses.city : "",
        state: addresses.state ? addresses.state : "",
        pincode: addresses.pincode ? addresses.pincode : "",
        country: addresses.country ? addresses.country : "",
      });
    }
    setLoading(false);
  }, [addresses]);

  const toggleEdit = () => setEditable(!editable);

  const submitForm = async () => {
    setLoading(true);
    setEditable(false);
    let res = await updateAddress({ address, id: user._id });
    if(res.data.success) {
      authReducer.updateUser(user._id);
      notification.success({message: "Successfully updated the data"})
    }
    else {
      notification.success({message: "Sorry unable to update the data"})
    }
    setLoading(false);
  };

  const updateAddressField = (e) => {
    setAddress({
      ...address,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="company-address-container app-white-card">
      <Spin spinning={loading}>
        <div className="app-flex-container-1">
          <h1 className="app-heading-3">Address</h1>

          {editable ? (
            <div>
              <NormalButton
                type={1}
                buttonText="Save"
                onClick={submitForm}
                margin={[0, 2]}
              />
              <NormalButton type={5} buttonText="Cancel" onClick={toggleEdit} />
            </div>
          ) : (
            <NormalButton
              type={8}
              icon={yellowEditIcon}
              buttonText="Edit"
              block={true}
              onClick={toggleEdit}
            ></NormalButton>
          )}
        </div>
        <Row gutter={[48, 0]}>
          <Col sm={24} md={12}>
            <div className="company-address-individual-data-container">
              <label className="app-label-1">Address</label>
              <InputField
                name="addressLine1"
                onChange={updateAddressField}
                value={address.addressLine1}
                block={true}
                disabled={!editable}
              ></InputField>
            </div>
            <div className="company-address-individual-data-container">
              <label className="app-label-1">City</label>
              <InputField
                value={address.city}
                onChange={updateAddressField}
                name="city"
                block={true}
                disabled={!editable}
              ></InputField>
            </div>
            <div className="company-address-individual-data-container">
              <label className="app-label-1">Landmark</label>
              <InputField
                value={address.landmark}
                onChange={updateAddressField}
                name="landmark"
                block={true}
                disabled={!editable}
              ></InputField>
            </div>
          </Col>

          <Col sm={24} md={12}>
            <div className="company-address-individual-data-container">
              <label className="app-label-1">State</label>
              <InputField
                value={address.state}
                onChange={updateAddressField}
                name="state"
                block={true}
                disabled={!editable}
              ></InputField>
            </div>
            <div className="company-address-individual-data-container">
              <label className="app-label-1">Country</label>
              <InputField
                value={address.country}
                onChange={updateAddressField}
                name="country"
                block={true}
                disabled={!editable}
              ></InputField>
            </div>
            <div className="company-address-individual-data-container">
              <label className="app-label-1">Pincode</label>
              <InputField
                value={address.pincode}
                onChange={updateAddressField}
                name="pincode"
                type="number"
                block={true}
                disabled={!editable}
              ></InputField>
            </div>
          </Col>
        </Row>
      </Spin>
    </div>
  );
};
ManageAddress.propTypes = {
  user: PropTypes.object.isRequired,
};

const ManageAddressContainer = connect(
  (state) => ({
    addresses: state.auth.address,
    user: state.auth.user,
  }),
  (dispatch) => ({
    authReducer: auth.getActions(dispatch),
  })
)(ManageAddress);
export default ManageAddressContainer;
