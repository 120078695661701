import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import Form from "./Component/Forms";
import TableforSupport from "./Component/Table";
import "./Support.less";
import { HeaderComponent } from "../Common";
import { getSupportRequestData } from "../../apis/ca";
import { connect } from "react-redux";

const Support = ({ user }) => {
  const [loading, setLoading] = useState(false);
  const [supportListData, setSupportListData] = useState([]);

  useEffect(() => {
    setLoading(true);
    getSupportRequestData(user.PAN).then((data) => {
      if (data.data.data)
        setSupportListData([...data.data.data.requests]);
      setLoading(false);
    });
    setLoading(false);
  }, [loading, user.PAN]);

  const updateSupportData = () => {
    setLoading(true);
    getSupportRequestData(user.PAN).then((data) => {
      if (data.data.data.requests)
        setSupportListData([...data.data.data.requests]);
      setLoading(false);
    });
    setLoading(false);
  };

  return (
    <>
      <div className="site-layout">
        <HeaderComponent name="Support" />
        <div className="support-container app-main-content-container">
          <Row gutter={24}>
            <Col sm={24} md={6} className="form">
              <Form updateSupportData={updateSupportData} />
            </Col>
            <Col sm={24} md={18} className="table">
              <TableforSupport
                supportListData={supportListData}
                loading={loading}
              />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

const SupportContainer = connect((state) => ({
  user: state.auth.user,
}))(Support);

export default SupportContainer;
