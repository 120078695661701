import { HeaderComponent } from "../Common";
import { Content } from "antd/lib/layout/layout";
import broadcastLogo from "../../assets/icons/activeIcons/activeBroadcastLogo.svg"
import { connect } from "react-redux";
import ca from '../../redux/modules/ca';
import { Table } from "antd";
import moment from "moment";
import { useEffect } from "react"
import { NormalButton } from "../ComponentLibrary";
import { withRouter } from "react-router";

const BroadcastMessage = ({ history, broadcastMessage, caReducer }) => {

    useEffect(() => {
        caReducer.caBroadcastMessage(2)
    }, [caReducer])

    const columns = [
        {
            width: "10%",
            title: "Date",
            dataIndex: "updatedAt",
            key: "updatedAt",
            render: updatedAt => (
                <p>{moment(updatedAt).format("DD/MM/YYYY")}</p>
            )
        },
        {
            width: "25%",
            title: "Title",
            dataIndex: "title",
            key: "title",
        },
        {
            width: "65%",
            title: "Message",
            dataIndex: "message",
            key: "message",
        },
    ]

    return (
        <div className="site-layout">
            <HeaderComponent show={true} showMoolCoins={true} icon={broadcastLogo} name="Broadcast" />
            <Content className="app-main-content-container">
                <div className="paper-wrapper">
                    <div className="paper-header-container m-bottom-12">
                        <p className="paper-heading">All Broadcast</p>
                        <div className="paper-heading-action">
                            <NormalButton
                                buttonText="Back to dashboard"
                                type={8}
                                onClick={() => {
                                    history.goBack();
                                }}
                            />
                        </div>
                    </div>
                    <Table
                        style={{ fontWeight: 500 }}
                        columns={columns}
                        dataSource={broadcastMessage.sort((a, b) => a.updatedAt > b.updatedAt ? -1 : 1)}
                        pagination={{ pageSize: 10 }}
                    />
                </div>
            </Content>
        </div>
    )
}

const BroadcastMessageContainer = connect(
    state => ({
        broadcastMessage: state.ca.broadcastMessage,
    }),
    dispatch => ({
        caReducer: ca.getActions(dispatch)
    })
)(BroadcastMessage)

export default withRouter(BroadcastMessageContainer);