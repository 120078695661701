import React, { useEffect, useState } from "react";
import {
  CALoginFlow,
  Dashboard,
  ClientManage,
  Support,
  Profile,
  BroadcastMessage,
} from "./../../components";
import { Switch, Route, Link } from "react-router-dom";
import { Layout, Menu } from "antd";
import "./PrivateRoutes.less";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import activeDashboard from "../../assets/icons/activeIcons/activeDashboard.svg";
import dashboard from "../../assets/icons/dashboard.svg";
import ca from "../../redux/modules/ca";
import activeClientManagementIcon from "../../assets/icons/activeIcons/activeClientManagementLogo.svg";
import clientManagementIcon from "../../assets/icons/clientManagementLogo.svg";
import activeDashboardLogo from "../../assets/icons/activeIcons/activeDashboardLogo.svg";
import dashboardLogo from "../../assets/icons/dashboardLogo.svg";
import activeSettingsLogo from "../../assets/icons/activeIcons/activeSettings.svg";
import settingsLogo from "../../assets/icons/settingsLogo.svg";
import broadcastLogo from "../../assets/icons/activeIcons/activeBroadcastLogo.svg";
import logo from "../../assets/icons/logo.svg";

const { Sider } = Layout;

const routes = {
  redirect: "/employee/dashboard",
  routes: {
    dashboard: {
      name: "Dashboard",
      path: "/dashboard",
      component: Dashboard,
      icon: dashboardLogo,
      activeIcon: activeDashboardLogo,
      view: true,
      subpath: "/ca",
    },
    login_flow: {
      path: "/login_flow",
      component: CALoginFlow,
      icon: "",
      activeIcon: "",
      view: false,
      subpath: "/ca",
    },
    client_manage: {
      path: "/client_manage",
      component: ClientManage,
      icon: clientManagementIcon,
      name: "Client Management",
      activeIcon: activeClientManagementIcon,
      view: true,
      subpath: "/ca",
    },
    profile: {
      name: "Settings",
      path: "/profile",
      component: Profile,
      icon: settingsLogo,
      activeIcon: activeSettingsLogo,
      view: false,
      subpath: "/ca",
    },
    support: {
      name: "Support",
      path: "/support",
      component: Support,
      icon: dashboard,
      activeIcon: activeDashboard,
      view: false,
      subpath: "/ca",
    },
    caBroadcastMessage: {
      name: "Broadcast",
      path: "/broadcast",
      component: BroadcastMessage,
      icon: broadcastLogo,
      activeIcon: broadcastLogo,
      view: false,
      subpath: "/ca",
    },
  },
};

function PrivateRoute({ loginstate, isLoggedIn, user, caReducer }) {
  const [openedTab, setOpenedTab] = useState("menu-link-image-0");
  
  useEffect(() => {
    caReducer.initiateCA();
  }, [isLoggedIn, caReducer]);

  return (
    <Switch>
      <Layout className="display-container">
        {!loginstate && (
          <Sider
            theme="light"
            style={{
              background: "#151b30",
              minWidth: "300px!important",
              width: "300px!important",
            }}
          >
            <div className="logo">
              <img src={logo} alt="mool logo" className="mool-logo"></img>
            </div>
            <Menu
              className="sider"
              theme="light"
              mode="inline"
              defaultSelectedKeys={`menu-link-0`}
              onSelect={({ key }) => setOpenedTab(key)}
            >
              {Object.keys(routes["routes"]).map((val, index) => {
                return (
                  routes["routes"][val].view && (
                    <Menu.Item
                    style={{
                      height: 40,
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                      key={`menu-link-${index}`}
                      icon={
                        <img
                        style={{
                          height: 14,
                          width: 14,
                        }}
                          alt="menuIcon"
                          key={`menu-link-image-${index}`}
                          className="sidebar-icon"
                          src={
                            openedTab === `menu-link-${index}`
                              ? routes["routes"][val].icon
                              : routes["routes"][val].icon
                          }
                        />
                      }
                    >
                      <Link
                        key={`menu-link-text-${index}`}
                        to={
                          routes["routes"][val].subpath +
                          routes["routes"][val].path
                        }
                        style={{color: '#fff'}}
                      >
                        {routes["routes"][val].name}
                      </Link>
                    </Menu.Item>
                  )
                );
              })}
            </Menu>
          </Sider>
        )}
        <Layout className="site-layout">
          {Object.keys(routes["routes"]).map((val, index) => {
            return (
              <Route
                exact
                key={`route-path-${index}`}
                path={
                  routes["routes"][val].subpath + routes["routes"][val].path
                }
                component={routes["routes"][val].component}
              />
            );
          })}
        </Layout>
      </Layout>
    </Switch>
  );
}

PrivateRoute.propTypes = {
  user: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  loginstate: PropTypes.bool.isRequired,
};

const PrivateContainer = connect(
  (state) => ({
    user: state.auth.user,
    isLoggedIn: state.auth.isLoggedIn,
    loginstate: state.auth.loginstate,
  }),
  (dispatch) => ({
    caReducer: ca.getActions(dispatch),
  })
)(PrivateRoute);

export default PrivateContainer;
