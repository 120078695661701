import ReducerFactory from "../utils/reducerFactory";
import { notification } from "antd";
import {
  changeLoginState,
  check,
  login,
  getUserFromId,
} from "../../apis/auth";

const initialState = {
  loading: false,
  user: {},
  image: "",
  address: {},
  isLoggedIn: false,
  error: null,
  loginstate: false,
  showNewPassword: false,
};
const reducerFactory = new ReducerFactory("auth", initialState);

reducerFactory.addAction(
  "LOGIN_LOADING",
  "loading",
  (status) => status,
  (state, action) => {
    state.loading = action.data;
    const newState = Object.assign({}, state);
    return newState;
  }
);

reducerFactory.addAction(
  "UPDATE_USER",
  "updateUser",
  async (data) => {
    let res = await getUserFromId(data);
    return res.data;
  },
  (state, action) => {
    if (action.data) {
      let user = action.data;
      state.image = user.image;
      state.address = user.address;
      state.familyDetails = user.familyDetails;
    }
    state.loading = false;
    const newState = Object.assign({}, state);
    return newState;
  }
);

reducerFactory.addAction(
  "LOGOUT",
  "logout",
  (status) => status,
  (state, action) => {
    state.isLoggedIn = false;
    state.user = {};
    localStorage.removeItem("mool_ca");
    const newState = Object.assign({}, state);
    return newState;
  }
);

reducerFactory.addAction(
  "LOGIN",
  "login",
  async (data) => {
    let res = await login(data);
    return res.data;
  },
  (state, action) => {
    if (action.data.success) {
      let user = action.data.data.user;
      if (user.status && user.role === "ca") {
        state.user = user;
        state.image = user.image;
        state.address = user.address;
        state.isLoggedIn = true;
        state.loginstate = action.data.data.user.loginstate < 1 ? true : false;
        localStorage.setItem("mool_ca", action.data.data.auth);
      } else if (user.role === "ca") {
        notification.error({
          message:
            "Sorry user is not confirmed! Please connect to our customer support",
        });
      } else {
        state.user = user;
        state.image = user.image;
        state.address = user.address;
        state.familyDetails = user.familyDetails;
        state.isLoggedIn = true;
        state.loginstate = action.data.data.user.loginstate < 4 ? true : false;
        localStorage.setItem("mool_auth", action.data.data.auth);
      }
    } else {
      notification.error({ message: action.data.message });
    }
    state.loading = false;
    const newState = Object.assign({}, state);
    return newState;
  }
);

reducerFactory.addAction(
  "CHECK_LOGIN",
  "check",
  async (data) => {
    reducerFactory.action("loading", true);
    let authExists = localStorage.getItem("mool_ca");
    if (!authExists) {
      return { data: { success: false } };
    }
    let res = await check();
    return res.data;
  },
  (state, action) => {
    if (action.data.success) {
      let user = action.data.data.user;
      if (user.status && user.role === "ca") {
        state.user = user;
        state.image = user.image;
        state.address = user.address;
        state.isLoggedIn = true;
        state.loginstate = action.data.data.user.loginstate < 1 ? true : false;
        localStorage.setItem("mool_ca", action.data.data.auth);
      } else if (user.role === "ca") {
        notification.error({
          message:
            "Sorry user is not confirmed! Please connect to our customer support",
        });
      } else {
        state.user = user;
        state.image = user.image;
        state.address = user.address;
        state.familyDetails = user.familyDetails;
        state.isLoggedIn = true;
        state.loginstate = action.data.data.user.loginstate < 4 ? true : false;
        localStorage.setItem("mool_auth", action.data.data.auth);
      }
    }
    state.loading = false;
    const newState = Object.assign({}, state);
    return newState;
  }
);

reducerFactory.addAction(
  "UPDATE_IMAGE",
  "updateImage",
  (status) => status,
  (state, action) => {
    state.image = action.data;
    state.loading = false;
    const newState = Object.assign({}, state);
    return newState;
  }
);

reducerFactory.addAction(
  "CHANGE_LOGIN_STATE",
  "changeLoginState",
  async (data) => {
    let res = await changeLoginState(data);
    return res.data;
  },
  (state, action) => {
    console.log({ action })
    if (action.data.success) {
      state.user = action.data.data.user;
      if (action.data.data.user.loginstate === 1) {
        window.location = `ca/dashboard`;
      }
    } else {
      notification.error({ message: "Unable to move to the next page" });
    }
    const newState = Object.assign({}, state);
    return newState;
  }
);

export default reducerFactory;
