const apiURLs = {
    dev: "https://apidev.moolfinance.com",
    prod: "https://endpoint.moolfinance.com",
    local: "http://localhost:4001"
}

const redirectURL = {
    dev: "https://stage.moolfinance.com",
    prod: "https://vetan.moolfinance.com",
    local: "https://vetan.moolfinance.com"
}

const env = process.env.REACT_APP_ENVIRONMENT;

module.exports = {
    endpoint: apiURLs[env],
    redirectURL:  redirectURL[env]
}
